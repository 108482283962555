import React from "react"
import Layout from "@components/layout"
import Container from "@components/layout/container"
import PropTypes from "prop-types"
import Heading from "@components/elements/heading"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import { graphql } from "gatsby"
import { processImages } from "@utils/process-images"
import Image from "@components/image"

const AwardsPage = ({ data }) => {
  const images = processImages(data.images.edges)

  return (
    <Layout
      fullWidth
      title={`Awards and Badges`}
      description={`A glance at Klipfolio's historic awards and badges.`}
    >
      <Flex gap="4rem" margin="0 0 8rem">
        <Container text>
          <Heading as="h2" center margin="4rem 0 2rem" marginSm="2rem 0 1rem">
            Klipfolio Awards and Badges
          </Heading>
        </Container>
        <Container>
          <Flex gap="4rem">
            <Grid columns="1fr 4fr" alignItems="center">
              <Heading as="h3">2015</Heading>
              <Grid columns="repeat(4, 1fr)" gap="2rem" columnsSm="1fr 1fr">
                <Image
                  objectFit="contain"
                  file={images["2fa00517-714f-40c6-be7a-f4d32adfb2c8"]}
                />
                <Image
                  objectFit="contain"
                  file={images["63d066fc-bb89-420a-81c5-6b28fa810c13"]}
                />
                <Image
                  objectFit="contain"
                  file={images["a192513f-cd22-4e6b-a511-e08bd6107e28"]}
                />
              </Grid>
            </Grid>
            <Grid columns="1fr 4fr" alignItems="center">
              <Heading as="h3">2016</Heading>
              <Grid columns="repeat(4, 1fr)" gap="2rem" columnsSm="1fr 1fr">
                <Image
                  objectFit="contain"
                  file={images["3206f754-f696-4c42-906b-ffcfca412cb6"]}
                />
                <Image
                  objectFit="contain"
                  file={images["4fe74c86-9439-4bbc-ad9a-cbb301d8d0c4"]}
                />
                <Image
                  objectFit="contain"
                  file={images["f125bd49-e307-4b4b-a758-51ff7e70ed35"]}
                />
              </Grid>
            </Grid>
            <Grid columns="1fr 4fr" alignItems="center">
              <Heading as="h3">2017</Heading>
              <Grid columns="repeat(4, 1fr)" gap="2rem" columnsSm="1fr 1fr">
                <Image
                  objectFit="contain"
                  file={images["dd8f145f-cf97-42e3-b6c7-c6f745a61ef0"]}
                />
                <Image
                  objectFit="contain"
                  file={images["60e46b66-d10c-4181-bae9-aff94529fd2c"]}
                />
                <Image
                  objectFit="contain"
                  file={images["a9a32c45-c37e-4d14-af69-42e6bfef50c9"]}
                />
                <Image
                  objectFit="contain"
                  file={images["9261251a-c3c3-435c-bed2-3497da5f27cd"]}
                />
              </Grid>
            </Grid>
            <Grid columns="1fr 4fr" alignItems="center">
              <Heading as="h3">2018</Heading>
              <Grid columns="repeat(4, 1fr)" gap="2rem" columnsSm="1fr 1fr">
                <Image
                  objectFit="contain"
                  file={images["29219c3b-7317-465c-9c30-7f242851b465"]}
                />
                <Image
                  objectFit="contain"
                  file={images["9c074a4f-6e9a-4150-b224-b3980c782035"]}
                />
              </Grid>
            </Grid>
            <Grid columns="1fr 4fr" alignItems="center">
              <Heading as="h3">2019</Heading>
              <Grid columns="repeat(4, 1fr)" gap="2rem" columnsSm="1fr 1fr">
                <Image
                  objectFit="contain"
                  file={images["28f3935f-3451-4452-9923-d47661ca3ef4"]}
                />
                <Image
                  objectFit="contain"
                  file={images["6fce594e-53c3-48ac-9c86-2b97224a1cf9"]}
                />
                <Image
                  objectFit="contain"
                  file={images["afd0ad35-b878-42ee-867b-c899c65a019e"]}
                />
                <Image
                  objectFit="contain"
                  file={images["c29f4e57-c74a-44d6-b0ee-bd5ad23f0168"]}
                />
                <Image
                  objectFit="contain"
                  file={images["d8ab0fd1-f34d-4611-8a43-8800ffb3f699"]}
                />
                <Image
                  objectFit="contain"
                  file={images["e59925f2-8f35-41bd-9c5a-afdac7f6488f"]}
                />
                <Image
                  objectFit="contain"
                  file={images["edefa78f-21b9-405a-a2fd-5c3c32396679"]}
                />
              </Grid>
            </Grid>
          </Flex>
        </Container>
      </Flex>
    </Layout>
  )
}

AwardsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AwardsPage

export const pageQuery = graphql`
  query AwardsQuery {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "2fa00517-714f-40c6-be7a-f4d32adfb2c8"
            "63d066fc-bb89-420a-81c5-6b28fa810c13"
            "a192513f-cd22-4e6b-a511-e08bd6107e28"
            "3206f754-f696-4c42-906b-ffcfca412cb6"
            "4fe74c86-9439-4bbc-ad9a-cbb301d8d0c4"
            "f125bd49-e307-4b4b-a758-51ff7e70ed35"
            "60e46b66-d10c-4181-bae9-aff94529fd2c"
            "9261251a-c3c3-435c-bed2-3497da5f27cd"
            "a9a32c45-c37e-4d14-af69-42e6bfef50c9"
            "dd8f145f-cf97-42e3-b6c7-c6f745a61ef0"
            "29219c3b-7317-465c-9c30-7f242851b465"
            "9c074a4f-6e9a-4150-b224-b3980c782035"
            "28f3935f-3451-4452-9923-d47661ca3ef4"
            "6fce594e-53c3-48ac-9c86-2b97224a1cf9"
            "afd0ad35-b878-42ee-867b-c899c65a019e"
            "c29f4e57-c74a-44d6-b0ee-bd5ad23f0168"
            "d8ab0fd1-f34d-4611-8a43-8800ffb3f699"
            "e59925f2-8f35-41bd-9c5a-afdac7f6488f"
            "edefa78f-21b9-405a-a2fd-5c3c32396679"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
  }
`
